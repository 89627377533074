import { useAuth } from "react-oidc-context";
import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  Navigate,
} from "react-router-dom";

const DashBoard = () => {
  const { user, signoutRedirect } = useAuth();

  return (
    <div>
      <h5>Hello {user?.profile.name} ! </h5>
      <p>{user?.profile.sub}</p>
      <p>{user?.profile.email}</p>

      <button
        onClick={() => signoutRedirect()}
        style={{
          alignSelf: "flex-start",
          margin: "auto",
        }}
      >
        Log out
      </button>
    </div>
  );
};

const Home = () => {
  const { user, isLoading, isAuthenticated, signinRedirect, signoutRedirect } =
    useAuth();

  if (isLoading) return <div>Loading ...</div>;

  if (!isAuthenticated && !isLoading) {
    signinRedirect({}).then();
  }

  return <section>{isAuthenticated && <Navigate to="dashboard" />}</section>;
};

const router = createBrowserRouter([
  {
    path: "",
    element: <Home />,
  },
  {
    path: "dashboard",
    element: <DashBoard />,
  },
  {
    path: "chat",
    element: <h5>Chat</h5>,
  },
  {
    path: "admin",
    element: <h5>Admin</h5>,
  },
]);

function App() {
  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
